<!--  -->
<template>
      <div class='courseList'>
            <CourseA v-if="item['type'] == '2'" :itemData="item"></CourseA>
            <CourseB v-if="item['type'] == '7'" :itemData="item"></CourseB>
            <CourseC v-if="item['type'] == '3'" :itemData="item" :str='tackAll.imageShow'></CourseC>
            <CourseC v-if="item['type'] == '8'" :itemData="item" :str='tackAll.imageSummary'></CourseC>
            <CourseC v-if="item['type'] == '4'" :itemData="item" :str='tackAll.tips[item.tipsIndex].image'></CourseC>
            <CourseD v-if="item['type'] == '6'" :itemData="tackAll.tips[item.tipsIndex]"></CourseD>
            <CourseE v-if="item['type'] == '9'" :itemData="tackAll"></CourseE>
            <CourseF v-if="item['type'] == '10'" :itemData="tackAll"></CourseF>
            <CourseG v-if="item['type'] == '11'" :itemData="tackAll"></CourseG>
            <!-- <CourseH v-if="true" :itemData="tackAll"></CourseH> -->
      </div>
</template>

<script>
      import CourseA from './courseA'
      import CourseB from './courseB'
      import CourseC from './courseC'
      import CourseD from './courseD'
      import CourseE from './courseE'
      import CourseF from './courseF'
      import CourseG from './courseG'
      import CourseH from './courseH'
      export default {
            components: {
                  CourseA,
                  CourseB,
                  CourseC,
                  CourseD,
                  CourseE,
                  CourseF,
                  CourseG,
                  CourseH
            },
            props: ["item", "tackAll"],
            data() {
                  return {
                        items: [],
                        tackAlls: []
                  }
            },
            //方法
            methods: {

            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  // if (this.item['type'] == '3') {
                  //       console.log('item------', this.tackAll.imageShow)
                  // }
                  // console.log('courseAitem', this.item)
                  // console.log('courseAtackAll', this.tackAll)

            },
            watch: {
                  immediate: true,
                  item(newVal, oldVal) {

                        this.items = newVal;
                        // console.log('courseListnewVal', newVal)
                        // console.log('newVal', oldVal)
                        // console.log('tackAll', this.tackAll)
                  },
                  tackAll(newVal, oldVal) {
                        this.tackAlls = newVal;
                        // console.log('courseListtackAll1', newVal)
                        // console.log('tackAll2', newVal)
                  }
            }
      }
</script>
<style lang='less' scoped>
      /* @import './less/..'; */
</style>