<!--  -->
<template>
      <div class='courseH flex-column-center'>
           <div class="courseFImg">

           </div>
      </div>
</template>

<script>
      export default {
            props: ["itemData"],
            data() {

                  return {

                  }
            },
            //方法
            methods: {

            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  // console.log('总',this.itemData)
            }
      }
</script>
<style lang='less' scoped>
      @import './less/courseH';
</style>