<template>
      <div class='assistant'>
            <a href="javascript:void(0);" ref="download" download="picture.png" v-show="false"></a>
            <div class="board" id="board" ref="board">
                  <canvas id="ctx_front" ref="ctx_front" :style="'cursor:' + cursor"></canvas>
                  <canvas id="ctx_base" ref="ctx_base" :style="'cursor:' + cursor"></canvas>
                  <canvas id="ctx_back" ref="ctx_back" :style="'cursor:' + cursor"></canvas>
                  <!-- +';max-height:'+textMaxHeight+'px' -->
                  <div contenteditable="true" id="text" :style="
              'font-size:' + (slide) + 'px;color:' + defaultColor +';max-width:'+ textMaxWidth +'px'
            " ref="text" :class="{abutt:abuttType}" @blur="handleTextBlur" @input="changeWidth">
                  </div>
            </div>

      </div>
</template>

<script>
      import html2canvas from "html2canvas";
      import cursors from "./js/cursor"
      import consolidateVue from '../myclass/template/consolidate.vue';
      import courseListVue from '../myclass/template/course/courseList.vue';
      export default {
            data() {
                  return {
                        dom: '',
                        instrument: ['true', 'false', 'true', 'true', 'false', 'false', 'true', 'true', 'false',
                              'true'
                        ],
                        //画板工具
                        cursor: `url('${cursors.pen1}'),auto`,
                        currentImg: {
                              url: "",
                              width: "",
                              height: "",
                              scale: 1,
                              index: 0,
                        },
                        canvasStore: [],
                        prevDis: true,
                        nextDis: true,
                        // 用于绘制的画板
                        canvas_front: null,
                        // 用于生成绘制后图片的画板
                        canvas_back: null,
                        // 底图画板，橡皮擦除时获取像素放到绘制画板中，达到不擦出底图的效果
                        canvas_base: null,
                        ctx_base: null,
                        ctx_front: null,
                        ctx_back: null,
                        baseMap: "",
                        slide: 1,
                        defaultColor: "#bfbfbf",
                        isExpand: 1,
                        showTools: 1,
                        canDraw: false,
                        text: "",
                        tl: 0,
                        tt: 0,
                        brushValue: 1,
                        eraserValue: 10,
                        graphValue: 1,
                        textValue: 1,
                        canvas: '',
                        context: '',
                        showType: true,
                        addType: false,

                        //弹窗
                        secondValue: '',
                        minuteValue: '',
                        centerDialogVisible: false,
                        switchStatus: false,
                        iconType: false,
                        visible: false,
                        // hint: false,
                        time: '',
                        timeType: '',
                        tooltip: false,
                        textMaxWidth: 'auto',
                        textMaxHeight: 'auto',
                        throttler: true,
                        lineFeedIndex: null,
                        newList: [],
                        calculateHeight: 0,
                        abuttType: false,
                        abuttType2: true,
                        viewport: 0,
                        changelineWidth: 0,
                        foucsType: false,


                  }
            },
            // props: ['URL', "deliveryIndex", "detail"],
            props: {
                  URL: {
                        type: String,
                        default: null,
                  },
                  deliveryIndex: {
                        type: Number,
                        default: null,
                  },
                  detail: {
                        type: Object,
                        default: function () {

                        }
                  },
                  toolWidth: {
                        type: Number,
                        default: null,
                  },
                  canvasSize: {
                        type: Number,
                        default: null,
                  }
            },

            components: {

            },
            //方法
            methods: {
                  //切换工具
                  itemSonck(name, value) {
                        if (name == '调整粗细') {
                              this.slide = value;
                              this.brushValue = value;

                        } else if (name == '调整颜色') {
                              this.defaultColor = value;
                        } else if (name == '橡皮檫') {
                              this.slide = value;
                              this.eraserValue = value;
                        } else if (name == '文本') {
                              this.slide = value;
                              this.textValue = value;
                        } else if (name == '图形') {
                              this.slide = 1;
                              if (value == 9) {
                                    this.handleChangeToolType(9)
                              } else if (value == 10) {
                                    this.handleChangeToolType(10)
                              } else {
                                    this.handleChangeToolType(11)
                              }
                        } else if (name == '田字格') {

                        }
                  },
                  //生成画板
                  switchover(index) {
                        switch (index) {

                              // case 0:
                              //       //关闭
                              //       // this.addType = false;
                              //       // this.showType = true;
                              //       break;
                              case 2:
                                    //颜色
                                    break;
                              case 3:
                                    //撤销
                                    // this.handlePrev();
                                    break;
                              case 6:
                                    //清除全部
                                    // this.handleClearCanvas();
                                    break;
                              case 7:
                                    // 截图上传
                                    // this.handleCanvas2Img();
                                    break;
                              case 9:
                                    //田字格
                                    // this.showType = !this.showType;
                                    // this.addType = false;

                                    // this.$nextTick(() => {
                                    //       this.scImg();
                                    // })

                                    break;
                        }
                  },
                  /** 初始化画布*/
                  handleInitCanvas(aurl) {
                        this.currentImg = {
                              url: aurl,
                              width: "",
                              height: "",
                              scale: 1,
                              index: 0,
                        };
                        this.canvasStore = [aurl];
                        this.prevDis = true;
                        this.nextDis = true;
                        // // 用于绘制的画板
                        this.canvas_front = document.getElementById("ctx_front");
                        // 用于生成绘制后图片的画板
                        this.canvas_back = document.getElementById("ctx_back");
                        // this.canvas_back.style.border = '1px solid red'
                        // 底图画板，橡皮擦除时获取像素放到绘制画板中，达到不擦出底图的效果
                        this.canvas_base = document.getElementById("ctx_base");
                        //获取

                        this.ctx_base = this.canvas_base.getContext("2d");
                        this.ctx_front = this.canvas_front.getContext("2d");

                        this.ctx_back = this.canvas_back.getContext("2d");
                        this.ctx_front.strokeStyle = this.defaultColor;
                        let img = new Image();
                        img.crossOrigin = "";
                        img.src = this.baseMap;
                        let _this = this;

                        img.onload = function () {
                              let width = parseInt(this.width);
                              let height = parseInt(this.height);
                              _this.currentImg.width = width;
                              _this.currentImg.height = height;
                              _this.canvas_front.width = width;
                              _this.canvas_front.height = height;
                              _this.canvas_back.width = width;
                              _this.canvas_back.height = height;
                              _this.canvas_base.width = width;
                              _this.canvas_base.height = height;
                              _this.ctx_front.drawImage(this, 0, 0, width, height);
                              _this.ctx_back.drawImage(this, 0, 0, width, height);
                              _this.ctx_base.drawImage(this, 0, 0, width, height);
                        };
                  },
                  /** 工具切换*/
                  handleChangeToolType(type) {
                        switch (type) {
                              case 1:
                                    //画笔
                                    this.cursor = `url('${cursors.pen1}'),auto`;
                                    // this.slide = this.brushValue;
                                    break;
                              case 4:
                                    //橡皮檫
                                    // this.slide = this.eraserValue;
                                    this.cursor = `url('${cursors.eraser}'),auto`;
                                    break;
                              case 5:
                                    //图形
                                    // this.slide = this.graphValue;
                                    break;
                              case 8:
                                    // 文字
                                    // this.slide = this.textValue;
                                    this.cursor = `url('${cursors.text}'),auto`;
                                    break;
                              case 9:
                                    // 线
                                    // this.slide = this.graphValue;
                                    this.cursor = `crosshair`;
                                    break;
                              case 10:
                                    // 圆
                                    // this.slide = this.graphValue;
                                    this.cursor = `crosshair`;
                                    break;
                              case 11:
                                    // 矩形
                                    // this.slide = this.graphValue;
                                    this.cursor = `crosshair`;
                                    break;
                              default:
                                    this.cursor = `url('${cursors.pen1}'),auto`;
                                    // this.slide = this.brushValue;
                                    break;
                        }
                        type ? this.handleDrawCanvas(type) : this.handleDrawCanvas(1);

                  },

                  handleDrawCanvas(type) {
                        this.canDraw = false;
                        let sx, sy, mx, my;
                        let text = document.getElementById("text");
                        let board = document.getElementById("board");
                        // let text = document.getElementsByClassName('el-textarea')[0];
                        //鼠标按下
                        let mousedown = (e) => {
                              this.ctx_front.strokeStyle = this.defaultColor;
                              this.ctx_front.lineWidth = this.slide;
                              e = e || window.event;
                              sx = e.offsetX - this.canvas_front.offsetLeft;
                              sy = e.offsetY - this.canvas_front.offsetTop;

                              const cbx = this.ctx_base.getImageData(
                                    e.offsetX - this.slide / 2,
                                    e.offsetY - this.slide / 2,
                                    this.slide * 2,
                                    this.slide * 2
                              );
                              this.ctx_front.moveTo(sx, sy);
                              this.canDraw = true;
                              switch (type) {
                                    case 1:
                                          //画笔
                                          this.ctx_front.beginPath();
                                          break;
                                    case 4:
                                          //橡皮檫
                                          this.ctx_front.putImageData(
                                                cbx,
                                                (e.offsetX - this.slide / 2) + 7,
                                                (e.offsetY - this.slide / 2) + 7
                                          );
                                          break;
                                    case 8:
                                          // 文字
                                          // 第二版
                                          // 初始化位置输入框位置
                                          this.handleTextBlur();
                                          this.text = "";
                                          // text.style.width = "auto";
                                          text.style.fontSize = 14 + this.slide * 10 + "px";
                                          text.style.color = this.defaultColor;
                                          text.style.zIndex = 10;
                                          text.style.display = "block";
                                          if (this.viewport - e.offsetX < 200) {
                                                let num = this.viewport - text.clientWidth;
                                                text.style.left = num +
                                                      "px";
                                                text.style.top =
                                                      e.offsetY + "px";
                                                this.tl = num;
                                                this.tt = e.offsetY;
                                          } else if (this.viewport - e.offsetX > 200 && e.offsetX < 200) {
                                                if (this.slide == 1) {
                                                      text.style.left =
                                                            this.toolWidth + "px";
                                                      text.style.top =
                                                            e.offsetY - 11 + "px";
                                                      this.tl = this.toolWidth;
                                                      this.tt = e.offsetY + 10;
                                                } else if (this.slide == 2) {
                                                      text.style.left =
                                                            97 + "px";
                                                      text.style.top =
                                                            e.offsetY - 15 + "px";
                                                      this.tl = this.toolWidth;
                                                      this.tt = e.offsetY + 18;
                                                } else {
                                                      text.style.left =
                                                            this.toolWidth + "px";
                                                      text.style.top =
                                                            e.offsetY - 15 + "px";
                                                      this.tl = this.toolWidth;
                                                      this.tt = e.offsetY + 31;
                                                }


                                          } else {
                                                // text.style.left =
                                                //       e.offsetX + "px";
                                                // text.style.top =
                                                //       e.offsetY + "px";
                                                // this.tl = e.offsetX;
                                                // this.tt = e.offsetY;
                                                this.location(text, e)
                                          }
                                          this.textMaxWidth = this.viewport - text
                                                .offsetLeft;
                                          this.textMaxHeight = this.$refs.board.clientHeight - text.offsetTop -
                                                10;


                                          // this.$refs.text.contentEditable = true;


                                          // setTimeout(() => {
                                          //       text.focus();
                                          // }, 1)
                                          break;
                              }
                        };
                        let mousemove = (e) => {
                              e = e || window.event;
                              mx = e.offsetX - this.canvas_front.offsetLeft;
                              my = e.offsetY - this.canvas_front.offsetTop + 7;
                              const cbx = this.ctx_base.getImageData(
                                    e.offsetX - this.slide / 2,
                                    e.offsetY - this.slide / 2,
                                    this.slide * 2,
                                    this.slide * 2
                              );
                              if (this.canDraw) {
                                    switch (type) {
                                          case 1:
                                                //画笔
                                                this.ctx_front.lineTo(mx, my);
                                                this.ctx_front.stroke();
                                                break;
                                          case 9:
                                                this.handleFrommatCanvas();
                                                this.ctx_front.beginPath();
                                                this.ctx_front.moveTo(sx, sy);
                                                this.ctx_front.lineTo(mx, my);
                                                this.ctx_front.stroke();
                                                break;
                                          case 10:
                                                this.handleFrommatCanvas();
                                                this.ctx_front.beginPath();
                                                // eslint-disable-next-line no-case-declarations

                                                let rds = Math.sqrt(
                                                      // (sx - 10 - mx) * (sx - 10 - mx) +
                                                      // (sy - 49 - my) * (sy - 49 - my)
                                                      (sx - 2 - mx) * (sx - 2 - mx) +
                                                      (sy - 2 - my) * (sy - 2 - my)
                                                );
                                                // this.ctx_front.arc(sx - 15, sy - 69, rds, 0, Math.PI * 2, false); 1
                                                // this.ctx_front.arc(sx - 75, sy + 10, rds, 0, Math.PI * 2, false); 2
                                                this.ctx_front.arc(sx - 10, sy, rds, 0, Math.PI * 2, false);
                                                this.ctx_front.stroke();
                                                break;
                                          case 4:
                                                //橡皮檫
                                                this.ctx_front.putImageData(
                                                      cbx,
                                                      e.offsetX - this.slide / 2,
                                                      e.offsetY - this.slide / 2
                                                );
                                                break;
                                          case 11:
                                                this.handleFrommatCanvas();
                                                this.ctx_front.beginPath();
                                                this.ctx_front.moveTo(sx, sy - 1);
                                                this.ctx_front.lineTo(mx, sy - 1);
                                                this.ctx_front.lineTo(mx, my - 1);
                                                this.ctx_front.lineTo(sx, my - 1);
                                                this.ctx_front.lineTo(sx, sy - 1);
                                                this.ctx_front.stroke();
                                                break;

                                    }
                              }
                        };
                        let mouseup = () => {
                              if (this.canDraw) {
                                    this.canDraw = false;
                                    this.ctx_front.closePath();
                                    if (type != 8) {
                                          this.handleSaveCanvasStore();
                                    }
                              }
                        };
                        this.canvas_front.onmousedown = (e) => mousedown(e);
                        this.canvas_front.onmousemove = (e) => mousemove(e);
                        this.canvas_front.onmouseup = (e) => mouseup(e);
                        this.canvas_front.onmouseout = (e) => mouseup(e);
                        this.canvas_front.onmouseleave = (e) => mouseup(e);
                  },
                  // 计算
                  location(text, e) {
                        if (this.slide == 1) {
                              text.style.left =
                                    e.offsetX - 10 + "px";
                              text.style.top =
                                    e.offsetY - 11 + "px";
                              // text.style.left =
                              //       e.offsetX + "px";
                              // text.style.top =
                              //       e.offsetY + "px";
                              this.tl = e.offsetX - 8;
                              this.tt = e.offsetY + 10;
                        } else if (this.slide == 2) {
                              text.style.left =
                                    e.offsetX - 8 + "px";
                              text.style.top =
                                    e.offsetY - 15 + "px";
                              this.tl = e.offsetX - 7;
                              this.tt = e.offsetY + 18;
                        } else {
                              // text.style.left =
                              //       e.offsetX - 8 + "px";
                              // text.style.top =
                              //       e.offsetY - 15 + "px";
                              // this.tl = e.offsetX - 7;
                              // this.tt = e.offsetY + 27;
                              text.style.left = e.offsetX + "px";
                              text.style.top = e.offsetY + "px";
                              this.tl = e.offsetX;
                              this.tt = e.offsetY + 50;
                        }
                        this.textMaxWidth = this.viewport - text
                              .offsetLeft;
                  },
                  /** 处理放大缩小*/
                  handleDrawImage() {
                        let _this = this;
                        let img = new Image();
                        let baseImg = new Image();
                        img.src = this.currentImg.url;
                        baseImg.src = this.baseMap;
                        _this.currentImg.width = _this.currentImg.width * this.currentImg.scale;
                        _this.currentImg.height = _this.currentImg.height * this.currentImg.scale;
                        img.onload = function () {
                              _this.canvas_front.width = _this.currentImg.width;
                              _this.canvas_front.height = _this.currentImg.height;
                              _this.canvas_back.width = _this.currentImg.width;
                              _this.canvas_back.height = _this.currentImg.height;
                              _this.ctx_front.drawImage(
                                    this,
                                    0,
                                    0,
                                    _this.currentImg.width,
                                    _this.currentImg.height
                              );
                              _this.ctx_back.drawImage(
                                    this,
                                    0,
                                    0,
                                    _this.currentImg.width,
                                    _this.currentImg.height
                              );
                        };
                        baseImg.onload = () => {
                              _this.canvas_base.width = _this.currentImg.width;
                              _this.canvas_base.height = _this.currentImg.height;
                              _this.ctx_base.drawImage(
                                    baseImg,
                                    0,
                                    0,
                                    _this.currentImg.width,
                                    _this.currentImg.height
                              );
                        };
                  },
                  /** 下载图片*/
                  handleCanvas2Img() {
                        let canvas = document.getElementById("ctx_back");
                        this.$refs.download.href = canvas.toDataURL();
                        this.$refs.download.click();
                  },
                  /** 清除画布*/
                  handleClearCanvas() {
                        this.handleInitCanvas();
                  },
                  handleFrommatCanvas() {
                        this.ctx_front.clearRect(
                              0,
                              0,
                              this.canvas_front.width,
                              this.canvas_front.height
                        );
                  },
                  /** 失焦或者回车绘制文本，框隐藏*/
                  handleTextBlur() {
                        this.abuttType = false;
                        let text = document.getElementById("text");
                        let Size = 14 + this.slide * 10 + 'px';
                        this.ctx_front.font = ` ${Size} Arial`;
                        this.ctx_front.fillStyle = this.defaultColor;
                        this.canvasTextAutoLine();
                        text.innerHTML && this.handleSaveCanvasStore();
                        // this.handleSaveCanvasStore();
                        //清空操作
                        text.style.display = "none";
                        text.innerHTML = '';
                        text.value = '';
                        this.text = "";
                        this.lineFeedIndex = null;
                        this.changelineWidth = 0;
                        this.throttler = true;
                        //处理换行

                  },
                  /** 上一步*/
                  handlePrev() {
                        for (let i = 0; i < this.canvasStore.length; i++) {
                        }
                        if (this.currentImg.index > 0) {
                              this.nextDis = false;
                              // this.currentImg.index -= 1; 
                              this.currentImg.index--;
                              this.currentImg.index == 0 ? this.prevDis = true : this.prevDis = false;
                              this.currentImg.url = this.canvasStore[this.currentImg.index];
                              this.currentImg.scale = 1;
                              this.handleDrawImage();
                        } else {
                              this.canvasStore = [];
                              this.currentImg = {
                                    url: "",
                                    width: "",
                                    height: "",
                                    scale: 1,
                                    index: 0,
                              };
                              this.prevDis = true;
                              this.handleClearCanvas();
                        }
                  },
                  /** 保存绘制*/
                  handleSaveCanvasStore() {
                        let url = this.canvas_front.toDataURL();
                        let image = new Image();
                        image.src = url;
                        image.onload = () => {

                              this.ctx_front.clearRect(
                                    0,
                                    0,
                                    this.canvas_front.width,
                                    this.canvas_front.height
                              );
                              this.ctx_front.drawImage(image, 0, 0, image.width, image.height);
                              this.ctx_back.drawImage(image, 0, 0, image.width, image.height);
                              const url2 = this.canvas_back.toDataURL();
                              this.currentImg.url = url2;
                              this.currentImg.index += 1;
                              this.canvasStore.push(url2);
                              this.prevDis = false;
                        };
                  },
                  //绘制田字格
                  draw() {
                        let dom = document.getElementsByClassName("cs");
                        for (let i = 0; i < dom.length; i++) {
                              this.drawGrid(dom[i]);
                        }
                  },
                  drawGrid(valve) {
                        this.canvas = valve;
                        this.context = this.canvas.getContext('2d');
                        this.canvas.height = this.canvas.width;
                        this.context.save();
                        this.context.strokeStyle = "rgb(203,195,129)";
                        this.context.beginPath();
                        this.context.moveTo(3, 3);
                        this.context.lineTo(this.canvas.width - 3, 3);
                        this.context.lineTo(this.canvas.width - 3, this.canvas.height - 3);
                        this.context.lineTo(3, this.canvas.height - 3);
                        this.context.closePath();

                        this.context.lineWidth = 6;
                        this.context.stroke();

                        this.context.beginPath();
                        this.context.moveTo(0, 0);
                        this.context.lineTo(this.canvas.width, this.canvas.height);

                        this.context.moveTo(this.canvas.width, 0);
                        this.context.lineTo(0, this.canvas.height);

                        this.context.moveTo(this.canvas.width / 2, 0);
                        this.context.lineTo(this.canvas.width / 2, this.canvas.height);

                        this.context.moveTo(0, this.canvas.width / 2);
                        this.context.lineTo(this.canvas.width, this.canvas.height / 2);
                        this.context.lineWidth = 1;
                        this.context.stroke();
                        this.context.restore();
                  },
                  //监测文字的宽度
                  changeWidth(e) {
                        let input = document.getElementById('text');
                        this.text = text.innerHTML;
                        let enums = {
                              '1': 34,
                              '2': 49,
                              '3': 65,
                        };
                        this.changelineWidth += this.ctx_front.measureText(e.data).width;
                        if (this.changelineWidth > this.viewport - input.offsetLeft && this.throttler) {
                              this.lineFeedIndex = input.innerHTML.length;
                              this.changelineWidth = 0;
                              this.throttler = false;
                        }
                        if (this.lineFeedIndex != null) {
                              let h = enums[this.slide] * parseInt(this.text.length / this.lineFeedIndex);
                              if (h >= this.textMaxHeight - enums[this.slide]) {
                                    input.style.top = '';
                                    this.abuttType = true;
                              }
                        }
                  },

                  //文字在canvas上换行 
                  canvasTextAutoLine() {

                        var c = document.getElementById("ctx_front");
                        var ctx = this.canvas_front.getContext("2d");
                        let input = document.getElementById('text');
                        let str = input.innerHTML;
                        let newdom = document.createElement('div');
                        newdom.innerHTML = str;
                        str = newdom.innerText || newdom.textContent;
                        newdom = null;
                        let initX = input.offsetLeft == 0 ? 0 : input.offsetLeft - this.toolWidth;
                        let palette = {
                              '1': 28,
                              '2': 39,
                              '3': 48,
                        }
                        let initY = input.offsetTop + palette[this.slide];
                        let enums = {
                              '1': 34,
                              '2': 49,
                              '3': 65,
                        };

                        let height = enums[this.slide];
                        let initHeight = 0;
                        let lineWidth = 0;
                        let canvasWidth = c.width;
                        let lastSubStrIndex = 0;
                        this.calculateHeight = height;
                        for (let i = 0; i < str.length; i++) {
                              lineWidth += ctx.measureText(str[i]).width;
                              let value = this.canvasSize == 1 ? 0 : this.toolWidth;
                              // - value
                              if (lineWidth > canvasWidth - initX) {
                                    ctx.fillText(str.substring(lastSubStrIndex, i + 1), initX, initY +
                                          initHeight);
                                    initHeight += height;
                                    lineWidth = 0;
                                    lastSubStrIndex = i + 1;
                              }
                              if (i == str.length - 1) {
                                    ctx.fillText(str.substring(lastSubStrIndex, i + 1), initX, initY +
                                          initHeight);
                              }
                        };


                  },
                  forbid() {
                        //关闭输入框
                        // this.$refs.text.contentEditable = false;
                        // this.$refs.text.style
                        // this.$refs.text.style.top = '';
                        // this.$refs.text.style.bottom = 0;



                  },
            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  this.viewport = document.body.clientWidth;
            },
            destroyed() {

            },
            watch: {
                  URL(newVal) {
                        this.baseMap = newVal;
                        this.dom = newVal;
                        // newVal
                        this.handleInitCanvas(newVal);
                        this.handleChangeToolType(1);
                  },
                  deliveryIndex(newVal) {
                        this.instrument[newVal] == 'true' ? this.switchover(newVal) : this.handleChangeToolType(
                              newVal)

                  },
                  detail(newVal) {
                        this.itemSonck(newVal.name, newVal.value)
                  },
                  toolWidth(newVal) {
                  },
                  canvasSize(newVal) {
                  }
            }
      }
</script>
<style lang='less' scoped>
      @import './less/assistant';

      #text {
            position: fixed;
            min-width: 200px;
            max-width: 100%;
            height: auto;
            display: none;
            border: 1px dashed gray;
            box-sizing: border-box;
            z-index: 9;
      }
</style>