<!--  -->
<template>
      <div class='courseC flex-align-center'>
            <div>
                  <img :src="str">
            </div>
      </div>
</template>

<script>
      export default {
            props: ["itemData", "str"],
            data() {
                  return {

                  }
            },
            //方法
            methods: {

            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  // console.log('图片', this.str)
            }
      }
</script>
<style lang='less' scoped>
      @import './less/courseC';
</style>