<template>
      <div class='consolidate'>
            <slider ref="consolidate_slider" :options="consolidateData" @slide='consolidateslide'>
                  <slideritem v-for="(item,index) in consolidateList" :key="index" style="color:red;">
                        <CourseList :item='item'></CourseList>
                  </slideritem>
            </slider>
      </div>
</template>

<script>
      import {
            slider,
            slideritem
      } from "vue-concise-slider";
      import CourseList from './course/courseList'
      export default {
            components: {
                  slider,
                  slideritem,
                  CourseList
            },
            data() {
                  return {
                        consolidateData: {
                              currentPage: 0
                        },
                        consolidateList: ['A', 'B', 'C', 'D', 'E'],
                  }
            },
            //方法
            methods: {
                  consolidateslide(data) {
                        // console.log('tackslide', data.currentPage)
                  },
                  // 下一页
                  consolidate_slideNext() {
                        this.$refs.consolidate_slider.$emit('slideNext')
                  },
                  // 上一页
                  consolidate_slidePre() {
                        this.$refs.consolidate_slider.$emit('slidePre')
                  },
            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  PubSub.subscribe('consolidate_slideNext', (msg, data) => {
                        this.consolidate_slideNext();
                  });
                  PubSub.subscribe('consolidate_slidePre', (msg, data) => {
                        this.consolidate_slidePre();
                  });
            }
      }
</script>
<style lang='less' scoped>
      @import './less/consolidate';
</style>