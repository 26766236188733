<!--  -->
<template>
      <div class='courseE flex-column-center'>
            <div class="text">
                  <img :src="itemData.imageShow">
            </div>
            <div class="sampleGraph flex-align-between">
                  <div class="sampleGraphbox" v-for="(item,index) in itemData.tips" :key="index">
                        <!-- 示例{{item}} -->
                        <div class="lineSon flex-column-center">
                              <span>窍门{{item.index}}</span>
                              <div class="dot"></div>
                        </div>
                        <div class="sampleSon">
                              <img :src="item.image">
                        </div>
                  </div>
                  <div class="line"></div>

            </div>
      </div>
</template>

<script>
      export default {
            props: ["itemData"],
            data() {

                  return {

                  }
            },
            //方法
            methods: {

            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  // console.log('总',this.itemData)
            }
      }
</script>
<style lang='less' scoped>
      @import './less/courseE';
</style>