<template>
      <div class='courseG flex-column-center'>
            <div class="courseGImg">
                  <ul class="ulImg">
                        <li v-for="(item,index) in itemData.tips" :key="index">
                               <img :src="item.image">
                              <span>{{item.content}}</span>
                        </li>
                  </ul>
            </div>
            <div class="courseGBottom">
                
            </div>
      </div>
</template>

<script>
      export default {
            props: ["itemData"],
            data() {
                  return {

                  }
            },
            //方法
            methods: {

            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  // console.log('总',this.itemData)
            }
      }
</script>
<style lang='less' scoped>
      @import './less/courseG';
</style>