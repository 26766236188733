<!--  -->
<template>
      <div class='courseB flex-align-center' ref="courseB">
            <div class="courseVideo" :id="'player'+itemData.id"></div>
      </div>
</template>

<script>
      import PubSub from 'pubsub-js'
      export default {
            props: ["itemData"],
            data() {
                  return {
                        player: '',
                  }
            },
            //方法
            methods: {
                  async loadPlayer() {
                        if (this.itemData.enclosure) {
                              let params = {
                                    vid: this.itemData.enclosure
                              };
                              this.$Api.Home.getVid(params).then(res => {
                                    let data = res.data.playsafe;
                                    this.player = new polyvPlayer({
                                          wrap: '#player' + this.itemData.id,
                                          vid: this.itemData.enclosure,
                                          'loop': false,
                                          'autoplay': false,
                                          'forceH5': true,
                                          'playsafe': data,
                                          'hideSwitchPlayer':true
                                    });

                              }).catch(err => {

                              })
                        }


                  },
                  pauseVideo() {
                        this.player.j2s_pauseVideo();
                  },
            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {

                  this.loadPlayer().then(res => {
                        PubSub.subscribe('courseB_pauseVideo', (msg, data) => {
                              this.pauseVideo();
                        });
                  });
            }
      }
</script>
<style lang='less' scoped>
      @import './less/courseB';
</style>