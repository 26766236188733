<!--  -->
<template>
      <div class='courseD flex-layout'>
            <div class="courseDimg">
                  <img :src="itemData.image">
            </div>
            <div class="narrate">
                  <p>目标：</p>
                  <p>{{itemData.content}}</p>
            </div>
      </div>
</template>

<script>
      export default {
            props: ["itemData"],
            data() {
                  return {
                        imgdata: ''
                  }
            },
            //方法
            methods: {

            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {

            },
            // watch: {
            //       itemData(newVal, oldVal) {
            //             console.log('newVal', newVal)
            //             console.log('oldVal', oldVal)
            //       }
            // }
            watch: {
                  itemData(newVal, oldVal) {
                        // console.log('newVal', newVal)
                        // console.log('oldVal', oldVal)
                  }
            }

      }
</script>
<style lang='less' scoped>
      @import './less/courseD';
</style>