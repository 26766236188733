<!--  -->
<template>
      <div class='courseA'>
            <div class="courseAPdf">
                  <pdf v-for="i in numPages" :key="i" :src="pdfUrl" :page="i"></pdf>
                  <!-- pdf -->
            </div>
      </div>
</template>

<script>
      import pdf from 'vue-pdf'
      import Axios from 'axios';
      export default {
            components: {
                  pdf
            },
            props: ["itemData"],
            data() {
                  return {
                        numPages: null,
                        pdfUrl: '',
                  }
            },
            //方法
            methods: {
                  async getJudge() {
                        // console.log('this.itemData.id', this.itemData.wordId);
                        let resData = await this.$Api.Home.getTaskId(this.itemData.wordId);
                        // .data.enclosure
                        // console.log('courseA+++++', resData);
                        let loadingTask = pdf.createLoadingTask(resData.data.enclosure);
                        loadingTask.promise.then(pdf => {
                              this.pdfUrl = loadingTask;
                              // console.log(' this.pdfUrl', this.pdfUrl)
                              this.numPages = pdf.numPages
                              // console.log('this.numPages', this.numPages)
                        }).catch(err => {
                              console.error('pdf 加载失败', err);
                        })
                  }
            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  
                  this.getJudge();
            },
            watch: {
                  itemdata(newVal, oldVal) {
                        //  console.log('courseAnewVal', newVal)
                        //newVal[0].words[0].id;
                        // this.getJudge()

                  }
            }
      }
</script>
<style lang='less' scoped>
      @import './less/courseA';
</style>