<!--  -->
<template>
      <div class='courseF flex-column-center'>
            <div class="courseFImg">
                  <ul class="ulImg">
                        <li v-for="(item,index) in itemData.tips" :key="index">
                              <!-- <span v-if=""> 左点要直右点收</span> -->
                              <img :src="item.image">
                        </li>
                  </ul>
            </div>
            <div class="courseFBottom">
                
            </div>
      </div>
</template>

<script>
      export default {
            props: ["itemData"],
            data() {
                  return {
                       trainImgs:[]
                  }
            },
            //方法
            methods: {

            },
            //生命周期 - 创建完成（访问当前this实例）
            created() {

            },
            //生命周期 - 挂载完成（访问DOM元素）
            mounted() {
                  // console.log('火车',this.itemData)
            },
            watch:{
                  itemData(newVal,oldVal){
                        this.trainImgs = newVal.tips;
                        // console.log('火车11111',this.trainImgs)
                  }
            }
      }
</script>
<style lang='less' scoped>
      @import './less/courseF';
</style>